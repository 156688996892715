<template>
    <div class="biz-mapping">
        <div class="ctrl-bar">
            <n-button
                type="primary"
                @click="redirectBizMappingXlsx"
                size="small"
                style="margin-left: auto"
            >
                编辑业务映射
            </n-button>
        </div>
        <div class="data-view">
            <n-data-table
                :columns="tableColumns"
                :data="tableData"
                :max-height="tableHeight"
                :loading="loading"
                :single-line="false"
                size="small"
                striped
            ></n-data-table>
        </div>
    </div>
</template>

<style lang="less">
@import '../../../common/common.less';
.biz-mapping {
    .common-content;
    .ctrl-bar {
        position: relative;
        border: 1px dotted #ddd;
        padding: 4px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
    }

    .data-view {
        margin-top: 10px;
    }
}
</style>

<script setup>
import { ref, onMounted } from 'vue';

import { NButton, NDataTable } from 'naive-ui';

import { SettingsAPI } from '@/common/API';

let loading = ref(true);

let tableHeight = ref(500);

const dataKeys = [
    'team',
    'group',
    'stage',
    'module',
    'lv0',
    'lv1',
    'lv2',
    'lv3',
    'lv4',
    'lv5',
    'lv6',
];

let tableColumns = ref([
    { title: 'id', key: 'id', width: 60, align: 'center' },
    ...dataKeys.map(key => {
        return {
            title: key,
            key,
            resizable: true,
            minWidth: 200,
            sorter: 'default',
        };
    }),
]);

let tableData = ref([]);

function redirectBizMappingXlsx() {
    window.open(
        'https://alidocs.dingtalk.com/i/nodes/qXomz1wAyjKVXR6mEe7N83Y9pRBx5OrE',
        '_blank'
    );
}

SettingsAPI.getMappingRaw().then(res => {
    loading.value = false;
    if (res.error_no !== 0) {
        return;
    }
    tableData.value = res.data;
});

onMounted(() => {
    tableHeight.value = document.body.clientHeight - 170;
});
</script>
